* {
    box-sizing: border-box;
}
html {
    font-size: 16px;
    font-family: $fontA, sans-serif;
}

body {
    font-size: 1rem;
    color: $blackColor;
    overflow-x: hidden;
}

ul {
    list-style-type: none;
}

button, input {
    border: none;
    outline: none;
    background: none;
}

button {
    cursor: pointer;
}


a {
    text-decoration: none;
    outline: none;
}


h1, h2, h3, h4, h5, h6, ul, p {
    margin: 0;
    padding: 0;
}

.container {
    @include container();
    @include sm(max-width, rem(510));
    @include md(max-width, rem(760));
    @include lg(max-width, rem(930));
    @include xl(max-width, rem(1150));
}

.layout {
    @include row-flex();
}

.title {
    font-family: $fontB, sans-serif;
    font-size: rem(30);
    font-weight: 300;
}

@include md-block() {
    .title {
        font-size: rem(50);
    }
}

@include lg-block() {
    .title {
        font-size: rem(70);
    }
}

.button {
    background-image: linear-gradient(180deg, #DBAF15 0%, #DB8C15 140.62%), linear-gradient(351.14deg, #E8951A -27.44%, #DC611B 135.76%);
    font-family: $fontC, sans-serif;
    color: $whiteColor;
    cursor: pointer;
    &:hover {
        background: #DBAF15;
    }
}

@include md-block() {
    .button {
        font-size: rem(20);
    }
}

.background-sections {
    background-image: linear-gradient(0deg, #1a75bc -60.72%, rgba(8, 21, 31, 0) 86.76%);
    background-color: #08131C;
    margin-top: rem(-1);
}
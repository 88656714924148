@import "../header/header";

.intro {
    position: relative;
    background-image: url("../img/general/background/intro/background_mobile.png");
    background-position: center center;
    background-repeat: no-repeat;
    padding-bottom: rem(72.5);
}

.intro__text {
    width: 100%;
}

.intro__title {
    @include col();
    @include size(12);
    @include size-md(8);
    @include shift-md-left(2);
    @include size-lg(12);
    @include shift-lg-left(0);
    text-align: center;
    color: $whiteColor;
    margin-top: rem(26);
    margin-bottom: rem(13);
}

.intro__label {
    @include col();
    @include size(11);
    text-align: center;
    color: $whiteColor;
    font-size: rem(14);
    margin: 0 auto;
}

.intro__more {
    @include col();
    @include size(12);
    display: flex;
    justify-content: center;
    margin-top: rem(26);
}

.intro__button {
    width: rem(225);
    height: rem(48);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro__plane {
    position: absolute;
    bottom: rem(-96);
    right: rem(-61);
    background-image: url("../img/general/intro/plane_mobile.png");
    background-repeat: no-repeat;
    width: rem(334);
    height: rem(215);
    z-index: 11;
}

.intro__cloud_right {
    position: absolute;
    bottom: rem(-150);
    right: rem(-241);
    background-image: url("../img/general/intro/cloud_right_mobile.png");
    background-repeat: no-repeat;
    width: rem(507);
    height: rem(302);
    z-index: 10;
}

.intro__cloud_left {
    display: none;
}

.background__video {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -9999;
}

@include md-block {
    .intro {
        background-image: url("../img/general/background/intro/background_tablet.png");
        padding-bottom: rem(173);
    }

    .intro__label {
        font-size: rem(28);
    }

    .intro__plane {
        bottom: rem(-137);
        right: rem(96);
        background-image: url("../img/general/intro/plane_tablet.png");
        width: rem(580);
        height: rem(373);
    }
    
    .intro__cloud_right {
        bottom: rem(-236);
        right: rem(-210);
        background-image: url("../img/general/intro/cloud_right_tablet.png");
        width: rem(880);
        height: rem(524);
    }
    
    .intro__button {
        width: rem(283);
        height: rem(64);
    }
}

@include lg-block {
    .background__video {
        display: block;
    }
    .intro {
        background: none;
    }
    .intro__label {
        font-size: rem(30);
    }
    .intro__more {
        margin-top: rem(75);
    }
    .intro__plane {
        right: rem(-100);
    }
    
    .intro__cloud_right {
        right: rem(-350);
    }

    .intro__cloud_left {
        position: absolute;
        display:block;
        bottom: rem(-121);
        left: rem(-750);
        background-image: url("../img/general/intro/cloud_left_desktop.png");
        width: rem(993);
        height: rem(542);
    }
}

@include xl-block {
    .intro__label {
        font-size: rem(36);
    }

    .intro__plane {
        right: rem(96);
    }
    
    .intro__cloud_right {
        right: rem(-210);
    }
    
    .intro__cloud_left {
        left: rem(-588);
    }
}

@media screen and (min-width: 1600px) {
    .intro__plane {
        background-image: url("../img/general/intro/plane_desktop.png");
        right: rem(63);
        bottom: rem(-263);
        width: rem(892);
        height: rem(574);
    }

    .intro__cloud_right {
        bottom: rem(-417);
        right: rem(-423);
        background-image: url("../img/general/intro/cloud_right_desktop.png");
        width: rem(1354);
        height: rem(806);
    }
}

@media screen and (min-width: 2000px) {
    .intro__cloud_left {
        left: rem(-200);
    }

    .intro__plane {
        right: rem(150);
    }
    
    .intro__cloud_right {
        right: rem(-300);
    }
}
@import './../aos/aos.scss';

.excellence {
    padding-top: rem(119.5);
    padding-bottom: rem(100);
}

.excellence__title {
    @include col();
    @include size(12);
    text-align: center;
    color: $whiteColor;
}

.excellence__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.excellence__item {
    @include col();
    @include size(9);
    @include size-md(4)
    margin-top: rem(40);
    text-align: center;
}

.excellence__item-title {
    margin-top: rem(26);
    color: $whiteColor;
    font-size: rem(18);
}

.excellence__item-text {
    margin-top: rem(10);
    color: $textGrayColor;
    font-size: rem(14);
}

.excellence__item-note {
    margin-top: rem(20);
    color: $textGrayColor;
    font-size: rem(10);
}


@include md-block() {
    .excellence {
        padding-top: rem(140);
    }
    .excellence__title {
        text-align: left;
    }
    .excellence__item {
        text-align: left;
    }
}

@include lg-block() {
    .excellence__item-title {
        font-size: rem(24);
    }
    .excellence__item-text {
        font-size: rem(16);
    }
}
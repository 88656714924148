.services__title {
    @include col();
    @include size(12);
    color: $whiteColor;
    text-align: center;
    margin-bottom: rem(37)
}

.services__list {
    display: flex;
    flex-wrap: wrap;
}

.services__item {
    position: relative;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    &:hover {
        .services__item_state_hover {
            opacity: 1;
        }
        .services__item-title {
            transform: translateY(0);
        }
        .services__item-text {
            visibility: visible;
            opacity: 1;
        }
    }
}

.services__item-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding: rem(30);
    &:before {
        position: absolute;
        z-index: 10;
        top: 30px;
        right: 15px;
        bottom: 30px;
        left: 15px;
        content: '';
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        border-image: linear-gradient(to left top, #E8D800, rgb(255, 187, 0) 50%, #E8D800);
        border-image-slice: 1;
        transform: scale(0, 1);
        transform-origin: 0 0;
        transition: opacity 0.35s, transform 0.35s;
    }
    &:after {
        position: absolute;
        z-index: 10;
        top: 15px;
        right: 30px;
        bottom: 15px;
        left: 30px;
        content: '';
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
        border-image: linear-gradient(to left top, #E8D800, rgb(255, 187, 0) 50%, #E8D800);
        border-image-slice: 1;
        transform: scale(1, 0);
        transform-origin: 100% 0;
        transition: opacity 0.35s, transform 0.35s;
    }
    &:hover {
        &:after, &:before {
            opacity: 1;
            transform: scale(1);
        }
    }
}

@include sm-block() {
    .services__item {
        width: 50%;
    }
}

@include lg-block() {
    .services__item {
        background-size: cover;
        width: 25%;
    }
}

.services__item_state_hover {
    background-image: linear-gradient(360deg, #152243 -12.45%, rgba(20, 32, 63, 0.65) 100%);
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .33s;
}

.services__item-title {
    color: $whiteColor;
    font-size: rem(24);
    text-align: center;
    transform: translateY(rem(-20));
    z-index: 10;
    transition: transform .33s;
}

.services__item-text {
    font-size: rem(14);
    color: $textGrayColor;
    text-align: center;
    margin-top: rem(10);
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    transition: opacity .33s;
}

@include lg-block() {
    .services__item-inner {
        padding: rem(20);
        &:before {
            top: 20px;
            right: 10px;
            bottom: 20px;
            left: 10px;
        }
        &:after {
            top: 10px;
            right: 20px;
            bottom: 10px;
            left: 20px;
        }
    }
    .services__item-text {
        width: auto;
        padding: 0 rem(50);
    }
}

@include xl-block() {
    .services__item-inner {
        &:before {
            top: 30px;
            right: 15px;
            bottom: 30px;
            left: 15px;
        }
        &:after {
            top: 15px;
            right: 30px;
            bottom: 15px;
            left: 30px;
        }
    }
}

.services__item_n_1 {
    background-image: url('../img/general/services/docs.png');
}
.services__item_n_2 {
    background-image: url('../img/general/services/flight.png');
}
.services__item_n_3 {
    background-image: url('../img/general/services/organization.png');
}
.services__item_n_4 {
    background-image: url('../img/general/services/cars.png');
}
.services__item_n_5 {
    background-image: url('../img/general/services/aviation.png');
}
.services__item_n_6 {
    background-image: url('../img/general/services/sea.png');
}
.services__item_n_7 {
    background-image: url('../img/general/services/events.png');
}
.services__item_n_8 {
    background-image: url('../img/general/services/catering.png');
}
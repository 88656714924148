.tours {
    background-color: #EFEFEF;
    padding-top: rem(43);
    padding-bottom: rem(63);
}

.tours__title {
    @include col();
    @include size(12);
    text-align: center;
}

.tours__content {
    margin-top: rem(20);
    padding-left: rem(20);
    padding-right: rem(20);
}

.tours__slide {
    outline: none;
    transition: box-shadow .33s ease-in-out;
    background-color: $whiteColor;
    padding-bottom: rem(17);
    &:hover {
        box-shadow: 0 0 20px #a5a5a5;
        .tours__slide-pic {
            img {
                transform: scale(1.12);
            }
        }
    }
}

.tours__slide-pic {
    overflow: hidden;
}

.tours__slide-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    color: $blackColor;
    &:visited, &:focus, &:active {
        color: $blackColor;
    }
}



.tours__slide-image {
    width: 100%;
    transition: transform .33s;
}

.tours__slide-content {
    padding: rem(17) rem(17) 0 rem(17);
    background-color: $whiteColor;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    color: $blackColor;
}

.tours__slide-title {
    font-size: rem(20);
    font-weight: 400;
    text-transform: uppercase;
}

.tours__slide-text {
    font-size: rem(14);
    margin-top: rem(10);
}

.tours__slide-bottom {
    margin-top: rem(72);
}

.tours__slide-price {
    font-size: rem(24);
    margin-top: rem(5);
}
.tours__slide-choose {
    margin-top: rem(15);
}

.tours__slide-button {
    width: 100%;
    height: rem(48);
    display: flex;
    align-items: center;
    justify-content: center;
}

@include sm-block {
    .tours__content {
        padding-right: 0;
    }
    .tours__slide {
        margin-right: rem(36);
    }
}

@include md-block {
    .tours {
        background-color: #EFEFEF;
        padding-top: rem(55);
        padding-bottom: rem(105);
    }
    .tours__content {
        margin-top: rem(43);
    }
    .tours__title {
        text-align: left;
    }

    .tours__slide-title {
        font-size: rem(18);
    }
}

@include lg-block {
    .tours__slide-title, .tours__slide-price {
        font-size: rem(20);
    }
    .tours__slide {
        padding-bottom: rem(32);
    }
    .tours__slide-content {
        padding: rem(32) rem(32) 0 rem(32);
    }
}

@media screen and (min-width:1600px) {
    .tours__slide-title, .tours__slide-price {
        font-size: rem(26);
    }
    .tours__slide-text {
        font-size: rem(16);
    }
}

@media screen and (min-width:1700px) {
    .tours__slide-title {
        font-size: rem(28);
    }
}
@media screen and (min-width:1806px) {
    .tours__slide-title, .tours__slide-price {
        font-size: rem(30);
    }
}
@media screen and (min-width:1806px) {
    .tours__slide-title, .tours__slide-price {
        font-size: rem(30);
    }
}
@media screen and (min-width:1900px) {
    .tours__slide-title, .tours__slide-price {
        font-size: rem(32);
    }
}
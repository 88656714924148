.footer {
    padding-top: rem(49);
    background-image: linear-gradient(180deg, #2F9CF1 -29.97%, rgba(8, 21, 31, 0) 100%);
    background-color:  #08131C;
}
.footer__title {
    @include col();
    @include size(12);
    @include size-lg(11);
    @include shift-lg-left(1);
    text-align: center;
    color: $whiteColor;
}

.footer__content {
    width: 100%;
}

.footer__form {
    @include col();
    @include size(12);
    @include size-md(6);
    @include size-lg(4);
    @include shift-lg-left(1);
    margin-top: rem(40);
}

.form__field {
    margin-top: rem(27);
}
.form__input {
    width: 100%;
    height: rem(48);
    border: 2px solid $whiteColor;
    padding-left: rem(15);
    color: $whiteColor;
    &::placeholder {
        color: rgba(255, 255, 255, 0.25);
    }
}

.form__submit {
    margin-top: rem(37);
}

.form__button {
    height: rem(48);
    width: 100%;
}
.footer__socials {
    @include col();
    @include size(8);
    @include shift-left(2);
    @include size-md(3);
    @include shift-md-left(0);
    display: flex;
    justify-content: center;
    margin-top: rem(58);
}
.footer__socials-link {
    margin-left: rem(20);
    &:first-child {
        margin-left: 0;
    }
}
.footer__socials-icon {
    width: rem(32);
    height: rem(32);
}

.footer__logo {
    @include col();
    @include size(12);
    @include size-md(6);
    margin-top: rem(42);
    display: flex;
    justify-content: center;
    img {
        width: rem(212);
        height: rem(70);
    }
}

.footer__contact {
    display: none;
}

@include md-block() {
    .footer {
        position: relative;
    }

    .footer__title {
        font-size: rem(24);
        text-align: left;
    }

    .footer__form {
        margin-top: rem(30);
    }

    .form__input, .form__button {
        height: rem(64);
    }

    .footer__logo {
        margin-top: 0;
        img {
            width: rem(289);
            height: rem(96);
        }
    }

    .footer__socials {
        justify-content: flex-start;
        margin-top: 0;
    }

    .footer__socials-link {
        &:last-child {
            display: none;
        }
    }

    .footer__bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: rem(165);
    }

    .footer__contact {
        @include col();
        @include size-md(3);
        display: inline-flex;
        justify-content: flex-end;
        a {
            font-size: rem(18);
            color: $whiteColor;
        }
    }

    .footer__bag {
        position: absolute;
        top: rem(-75);
        right: 0;
        background-image: url('../img/general/footer/bag_tablet.png');
        width: rem(376);
        height: rem(506);
    }
}

@include lg-block() {
    .footer__layout {
        position: relative;
    }
    .footer__title {
        font-size: rem(36);
    }
    .footer__bag {
        top: rem(-115);
        right: rem(0);
        background-image: url('../img/general/footer/bag_desktop.png');
        width: rem(518);
        height: rem(532);
    }
    .footer__logo {

        img {
            width: rem(386);
            height: rem(128);
        }
    }
}

@include xl-block() {
    .footer__bag {
        right: rem(100);
    }
}
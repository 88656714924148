.logo {
    @include col();
    @include size(12);
    @include size-md(6);
    @include size-xl(4);
    display: flex;
    justify-content: center;
    margin-bottom: rem(26);
}

.logo__image {
    width: rem(212);
    height: rem(70);
}

.header__socials {
    @include col();
    @include size(8);
    @include shift-left(2);
    @include size-md(3);
    @include shift-md-left(0);
    @include size-xl(4);
    display: flex;
    justify-content: center;
}
.header__socials-link {
    margin-left: rem(20);
    &:first-child {
        margin-left: 0;
    }
}
.header__socials-icon {
    width: rem(32);
    height: rem(32);
}

.header__callback {
    display: none;
}

@include md-block() {
    .logo__image {
        width: rem(289);
        height: rem(96);
    }
    .logo {
        order: 2;
    }
    .header__socials {
        order: 1;
        margin-top: rem(31);
    }
    .header__socials-link {
        &:last-child {
            display: none;
        }
    }
    .header__callback {
        @include col();
        @include size-md(3);
        @include size-xl(4);
        display: flex;
        flex-direction: column;
        order: 3;
    }
    .header__callback-button {
        padding: 0;
        margin-top: rem(30);
    }
    .header__callback-phone {
        margin-top: rem(20);
    }
    .header__callback-button, .header__callback-phone {
        font-size: rem(18);
        color: $whiteColor;
        text-align: right;
    }
}

@include xl-block {
    .header__socials {
        justify-content: flex-start;
    }
    .header__callback {
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        margin-top: rem(37);
    }
    
    .header__callback-button, .header__callback-phone {
        margin: 0;
    }
    .header__callback-button {
        margin-right: rem(40);
    }
    .logo__image {
        width: rem(386);
        height: rem(128);
    }
}
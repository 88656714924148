@charset "UTF-8";

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 48px;
    width: 48px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
}


.slick-prev {
    background-image: url("../img/svg/slider-arrow-prev_icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    left: rem(50);
    z-index: 999;
}

.slick-next {
    background-image: url("../img/svg/slider-arrow-next_icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    right: rem(50);
    z-index: 999;
}

@include xl-block() {
    .slick-prev,
    .slick-next {
        width: 72px;
        height: 72px;
    }
}

.slick-dots {
    margin-top: rem(38);
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 7px;
        width: 7px;
        margin: 0 9px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 7px;
            width: 7px;
            background-color: #700130;
            border-radius: 50%;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            opacity: .5;
        }
        &.slick-active {
            button {
                width: 12px;
                height: 12px;
                opacity: 1;
            }
        }
    }
}

@include md-block() {
    .slick-dots {
        margin-top: rem(23);
    }
}
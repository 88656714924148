//Variables Font
$fontA: "Montserrat";
$fontB: "MuseoCyrl";
$fontC: "ProximaNova";

@font-face {
    font-display: swap;
    font-family: $fontC;
    src: url("./../fonts/" + $fontC + "-Regular.woff") format("woff"), url("./../fonts/" + $fontA + "-Regular.woff2") format("woff2");
    font-weight: 400;
}

@font-face {
    font-display: swap;
    font-family: $fontA;
    src: url("./../fonts/" + $fontA + "-Bold.woff") format("woff"), url("./../fonts/" + $fontA + "-Bold.woff2") format("woff2");
    font-weight: 600;
}

@font-face {
    font-display: swap;
    font-family: $fontA;
    src: url("./../fonts/" + $fontA + "-Regular.woff") format("woff"), url("./../fonts/" + $fontA + "-Regular.woff2") format("woff2");
    font-weight: 400;
}

@font-face {
    font-display: swap;
    font-family: $fontB;
    src: url("./../fonts/" + $fontB + "-300.woff") format("woff"), url("./../fonts/" + $fontB + "-300.woff2") format("woff2");
    font-weight: 300;
}